import { ComponentConfig } from '@/shared/libs/dynamic-components-render.tsx';
import { navSections } from '@/shared/mockData/comfort-service-mock';
import styles from '@pages/comfort-service-page/ui/comfort-service-page.module.css';
import imageUrl from '@shared/ui/assets/bg-images/TrustedDeveloper.png';
import stylex from '@stylexjs/stylex';
import { style } from '../ui/styles';
import { blocks, header, qrCodes, url } from './mock-blocks';

export interface CommonProps {
  [key: string]: unknown;
}

export const pageConfig = (): ComponentConfig<CommonProps>[] => [
  {
    component: 'section',
    props: { className: styles.navContainer },
    children: [
      {
        component: 'CardWithFloatBtn',
        props: {
          btnName: 'УЗНАТЬ ПОДРОБНО',
          redirectUrl: '/',
          firstSentence: 'Обеспечиваем премиальное качество жизни',
          title: 'СЛУЖБА КОМФОРТА',
        },
      },
      {
        component: 'Navbar',
        props: { navSections, centeredSections: true },
      },
    ],
  },
  {
    component: 'section',
    props: { ...stylex.props(style.twoColls) },
    children: [
      {
        component: 'Heading',
        props: { header: header, style: { ...stylex.props(style.header) } },
      },
      {
        component: 'column',
        props: { ...stylex.props(style.content) },

        children: [
          {
            component: 'column',
            props: { ...stylex.props(style.infoBlock) },
            children: [
              {
                component: 'TextParagraphs',
                props: { blocks: blocks },
              },
              {
                component: 'QrCodeBlock',
                props: { qrCodes, style: { ...stylex.props(style.qrCodes) } },
              },
            ],
          },
          {
            component: 'VideoElement',
            props: {
              url: url,
              style: { ...stylex.props(style.videoBlock) },
            },
          },
        ],
      },
    ],
  },

  {
    component: 'TransitionCard',
    props: {
      bgUrl: imageUrl,
      title: 'ДЕВЕЛОПЕР, КОТОРОМУ МОЖНО ДОВЕРЯТЬ',
      link: '/',
    },
  },
];
