export const routes = {
  public: {
    home: '' as const,
    brokerPage: '/broker' as const,
    contactsPage: '/contacts' as const,
    bestEmployerPage: '/best-employer' as const,
    allNewsPage: '/kompaniya/novosti' as const,
    oneNewsPage: '/kompaniya/novosti/:id' as const,
    videoPlayer: '/video' as const,
    tendersCooperationPage: '/sotrudnichestvo/tendery-developera' as const,
    comfortService: '/sluzhba-komforta' as const,
  },
  admin: {},
};

export type RouteKeys = keyof typeof routes;
