import stylex from '@stylexjs/stylex';
import { styles } from './styles';

export interface IQrCode {
  svg: string;
  name: string;
  btn: string;
  url: string;
}

export interface IQRCodeWithNameProps {
  qr: IQrCode;
}

export const QrCodeWithName = ({ qr }: IQRCodeWithNameProps) => {
  return (
    <div {...stylex.props(styles.block)}>
      <div {...stylex.props(styles.qr)}>{qr.svg}</div>
      <div {...stylex.props(styles.name)}>{qr.name}</div>
      <div {...stylex.props(styles.btn)} role="button" onClick={() => (window.location.href = qr.url)}>
        {qr.btn}
      </div>
    </div>
  );
};
