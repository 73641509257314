import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const style = stylex.create({
  twoColls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '6.25rem',
    marginTop: '1.25rem',
    marginBottom: '6.25rem',
    '@media (max-width: 767px)': {
      gap: 0,
      marginTop: '4.267rem',
      marginBottom: '14.933rem',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 3.125rem 0px 11.042rem',
    width: '100%',
    '@media (max-width: 767px)': {
      display: 'flex',
      flexDirection: 'column-reverse',
      padding: ' 0',
      gap: '8.533rem',
      marginTop: '14.933rem',
    },
  },
  header: {
    display: 'flex',
    paddingLeft: '3.125rem',
    alignItems: 'center',
    gap: '0.521rem',
    fontFamily: fonts.din2014Light,
    fontSize: '1.042rem',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '140%',
    color: colors.primaryText,
    '@media (max-width: 767px)': {
      fontSize: '4.267rem',
      lineHeight: '150%',
      paddingLeft: '4.267rem',
      gap: '2.267rem',
    },
  },
  videoBlock: {
    borderRadius: '0px 0px 0px 2.917rem',
    width: '46.25rem',
    height: '29.58rem',
    '@media (max-width: 767px)': {
      borderRadius: '0px 0px 0px 14.933rem',
      width: '91.733rem',
      height: '58.667rem',
    },
  },

  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '2.917rem',
    width: '30.42rem',
    '@media (max-width: 767px)': {
      width: '91.733rem',
      gap: '8.533rem',
    },
  },

  qrCodes: {
    display: 'flex',
    alignItems: 'center',
    gap: '4.583rem',
    '@media (max-width: 767px)': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '2.133rem',
      width: '100%',
    },
  },
});
