import AndroidAppQr from '@/shared/ui/icons/AndroidAppQr';
import { AppStore } from '@/shared/ui/icons/AppStore';
import { GooglePlay } from '@/shared/ui/icons/GooglePlay';
import IosAppQr from '@/shared/ui/icons/IosAppQr';

export const blocks = [
  {
    header: 'СЛЫШИМ ВАС',
    details:
      'Для нас важно, чтобы в доме всё было именно так, как хотят клиенты, и мы прислушиваемся к их пожеланиям, проводим живые опросы и онлайн-голосования в мобильном приложении.',
  },
  {
    header: 'ВСЕГДА НА СВЯЗИ',
    details:
      'Для удобства клиентов мы принимаем обращения (заказы услуг, жалобы, предложения) по телефону, через консьержа и в мобильном приложении.',
  },
  {
    header: 'СОВЕРШЕНСТВУЕМСЯ',
    details:
      'Мы совершенствуем существующие сервисы и внедряем новые. Скачать наше приложение Sminex Comfort просто: наведите камеру вашего смартфона на QR-код.',
  },
];

export const url = 'https://www.sminex.com/bitrix/templates/sminex/video/Sminex_mobile_new.mp4';

export const header = 'Президент компании';

export const qrCodes = [
  {
    svg: <AndroidAppQr />,
    name: 'ANDROID',
    btn: <GooglePlay />,
    url: 'https://play.google.com/store/apps/details?id=sminex.ru.sminex_app',
  },
  {
    svg: <IosAppQr />,
    name: 'IOS',
    btn: <AppStore />,
    url: 'https://apps.apple.com/am/app/sminex-comfort/id1350615149',
  },
];
